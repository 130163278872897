import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { KeyFill, ShieldLockFill, BellFill } from 'react-bootstrap-icons';
import security  from "./img/security.png"

function Privacy() {

  const navigate = useNavigate();
  return (
    <div className="mt-10 d-flex justify-content-center align-items-center">
    <Card style={{ width: '100%', maxWidth: '400px' }}>
      <Card.Img variant="top" src={security} />
      <Card.Body>
        <Card.Title>How to keep your account secure</Card.Title>
        <Card.Text>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <KeyFill fontSize={29} color='#8c939d' />
            <span style={{ marginLeft: '5px' }}>
              We'll take you through some steps to help protect your account.
            </span>
          </div>
        </Card.Text>
        <Card.Text>
          <div style={{ display: 'flex', alignItems: 'center',marginTop:"10px" }}>
            <ShieldLockFill fontSize={20} color='#8c939d' />
            <span style={{ marginLeft: '5px' }}>
              Your password is OK
            </span>
          </div>
        </Card.Text>
        <Card.Text>
          <div style={{ display: 'flex', alignItems: 'center',marginTop:"10px" }}>
            <BellFill fontSize={25} color='#8c939d' />
            <span style={{ marginLeft: '5px' }}>
              We'll take you through some steps to help protect your account.
            </span>
          </div>
        </Card.Text>
        <Button style={{ backgroundColor: "#0d6efd", width: "100%", fontWeight: 'bold' }} onClick={() => navigate("/support")} block>Continue</Button>
      </Card.Body>
    </Card>
  </div>
  )
}

export default Privacy