import {Routes, Route} from "react-router-dom";
import Support from "./component/Support";
import Home from "./component/Home";
import Privacy from "./component/Privacy";
import 'bootstrap/dist/css/bootstrap.min.css';
import EmailTemplate from "./component/EmailTemplate";
import { useEffect } from "react";

function App() {

  const RedirectToExternal = () => {
    useEffect(() => {
      window.location.href = "https://web.facebook.com/business/tools/meta-business-suite";
    }, []);
    return null; 
  };

  return (
    <>

    <Routes >
        <Route path="/" element={<RedirectToExternal />} />
      <Route path="/support" element={ <Support /> } />
      <Route path="/Privacy" element={ <Privacy /> } />
      <Route path="/beta-ads-app-invite" element={ <EmailTemplate /> } />
    </Routes>
    </>
  );
}

export default App;
